import React, {useState} from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useTheme } from '../ThemeContext'; 
import './Header.css';

function Header({scrollToAboutMe, scrollToSkill, scrollToWork, scrollToContact}) {
  const { theme, toggleTheme } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const themeToggleText = theme === 'light' ? 'Dark Mode' : 'Light Mode';

  return (
    <AppBar position="static" sx={{backgroundColor: 'var(--primary-bg-color)', color: 'var(--primary-text-color)'}} elevation={0}>
      <Toolbar style={{paddingLeft: 26, paddingRight: 26, paddingTop: 12, paddingBottom: 12}}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <div className="logo-container">
            <div className="logo-img"></div>
            <label className="logo-name">Amirularif</label>
          </div>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ display: { xs: 'block', md: 'none' } }}
          onClick={handleMenu}
          className="menu-icon"
        >
        <MenuIcon sx={{color:'inherit'}}/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
          sx={{
            '& .MuiPaper-root': { 
              borderRadius: '8px', 
              width: 'fit-content', 
            }
          }}
        >
          <MenuItem onClick={() => { handleClose(); scrollToAboutMe(); }} style={{fontFamily:'inherit', fontSize: '0.9rem'}}>About Me</MenuItem>
          <MenuItem onClick={() => { handleClose(); scrollToSkill(); }} style={{fontFamily:'inherit', fontSize: '0.9rem'}}>Skills</MenuItem>
          <MenuItem onClick={() => { handleClose(); scrollToWork(); }} style={{fontFamily:'inherit', fontSize: '0.9rem'}}>Projects</MenuItem>
          <MenuItem onClick={() => { handleClose(); scrollToContact(); }} style={{fontFamily:'inherit', fontSize: '0.9rem'}}>Contact Me</MenuItem>
          <MenuItem onClick={() => { handleClose(); toggleTheme(); }} style={{fontFamily:'inherit', fontSize: '0.9rem'}}>{themeToggleText}</MenuItem>
        </Menu>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent:'flex-end' }} className="header-buttons">
        <IconButton sx={{ marginRight: '1.5rem', color: 'var(--primary-text-color)'}} onClick={toggleTheme}>
          {theme === 'light' ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
        <Button onClick={scrollToAboutMe} color="inherit" style={{ fontSize: '1rem', fontWeight: 500, marginRight: '1rem' }}>About Me</Button>
        <Button onClick={scrollToSkill} color="inherit" style={{ fontSize: '1rem', fontWeight: 500, marginRight: '1rem' }}>Skills</Button>
        <Button onClick={scrollToWork} color="inherit" style={{ fontSize: '1rem', fontWeight: 500, marginRight: '1rem' }}>Projects</Button>
        <button onClick={scrollToContact} className="btn-grad" sx={{letterSpacing: '.5px', fontWeight: 600, color: 'white' }}>CONTACT ME</button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

