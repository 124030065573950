import React, {forwardRef} from 'react';
import { SowCard } from '../Elements';
import sowData from '../Model/SowData';
import './AboutMeSection.css';


const AboutMeSection = forwardRef((props, ref) => {

    return (
        <div ref={ref} className="aboutme-section">
            <div className="title-container">
                <label className="page-title ">About Me</label>
                <div className="title-underline"></div>
            </div>
            <div className="content-container">
                <div className="aboutme-container">
                    <div className="image-container">
                        <div className="img-bg-container">
                            <img src="/profilepic2.png" alt="Profile" className="profile-img" />
                        </div>  
                    </div>
                    <div className="desc-container">
                        <label className="desc-title">
                            Hello! Im Amirul Arif. 
                        </label>
                        <label className="desc">I am a Frontend Application Developer from Malaysia. I make it my mission to translate beautiful user-interface and user-focused designs into fully functional Web/Mobile applications.</label>
                        <div className="stats">
                            <div className="years-stat-container">
                                <label className="years">2</label> 
                                <label className="year-stat-label">Years of experience</label>
                            </div>
                            <div className="project-stat-container">
                                <label className="project">4</label> 
                                <label className="project-stat-label">Projects completed</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sow-container">
                    <label className="sow-title">My Scope of Works</label>
                    {sowData.map((sow, index) => (
                        <SowCard key={index} title={sow.title} description={sow.description} imageUrl={sow.imageUrl} />
                    ))}
                </div>
            </div>
        </div>
    );
});

export default AboutMeSection;