import React, {forwardRef} from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import './ContactCard.css';

const ContactCard = forwardRef(({ title, linkUrl, className, desc }, ref) => {
    const duration = desc === "Gmail" ? '1s' : title === "Whatsapp" ? '2s' : '3s';

    const renderIcon = () => {
        switch (desc) {
            case "Gmail":
                return <MailOutlinedIcon sx={{ color: 'var(--primary-text-color)', fontSize: '2rem'}} />;
            case "Whatsapp":
                return <WhatsAppIcon sx={{ color: 'var(--primary-text-color)', fontSize: '2rem'}} />;
            case "Linkedin":
                return <LinkedInIcon sx={{ color: 'var(--primary-text-color)', fontSize: '2rem'}} />;
            default:
                return null;
        }
    };

    return (
        <div ref={ref} className={`contact-card ${className}`} style={{ animationDuration: duration}}>
            <div className="img-container">
                {renderIcon()}
            </div>
            <div className="description-container">
                <div className="desc-title-container">
                    <label className="title">{title}</label>
                </div>
                <div className="desc-nav">
                    <a href={linkUrl} target="_blank" rel="noopener noreferrer" className="learn-more" style={{color: 'white'}}> Take me there </a>
                </div>
            </div>
        </div>
      );
    });

export default ContactCard;