
const sowData = [
    {
        title: "Web Design",
        description: "Passionate in designing beautiful and clean websites user interface using design tools such as Figma",
        imageUrl: "webdesign.png" 
    },
    {
        title: "Mobile Development",
        description: "Experience in creating Frontend Components for websites using latest popular frameworks",
        imageUrl: "mobileapp.png" 
    },
    {
        title: "Web Development",
        description: "Experience in creating Frontend Components for websites and able to create a working Landing or Marketing Page",
        imageUrl: "webdev.png" 
    },
    {
        title: "UI/UX Design",
        description: "Certification in User Experience and User Interaction by Google and  always implements it in every project",
        imageUrl: "uiux.png" 
    },
    {
        title: "Animating Graphics",
        description: "Love to animate components and create graphics to add into a website using Tools like Blender and Illustrator",
        imageUrl: "animation.png" 
    }
];

export default sowData;
