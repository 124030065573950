import React, {forwardRef} from 'react';
import { ProjectCard } from '../Elements';
import './WorkSection.css';


const WorkSection = forwardRef((props, ref) => {

    const projects = [
        {
            title: "CMMR Landing Page",
            description: "Landing Page Website for CMMR Admin Portal and CMMR Mobile App built using ReactJs",
            imageUrl: "Project-SS5.png",
            siteUrl: "https://cmmr.app/",
            githubUrl: "https://github.com/Amirularif/CMMRLandingPage",
            labels: ["ReactJs", "Javascript", "HTML", "CSS", "MUI", "Figma"]
        },
        {
            title: "FocuSpace Website",
            description: "An Immersive fully customizable Ambient Sound Website to help focus built using ReactJs",
            imageUrl: "Project-SS3.png",
            githubUrl: "https://github.com/Amirularif/FocuSpace",
            labels: ["ReactJs", "Javascript", "Tensorflow", "Python", "Bootstrap", "Figma"]
          },
          {
            title: "E-Commerce Website",
            description: "Pokemon Card E-commerce website featuring a shopping cart functionality using ReactJs",
            imageUrl: "Project-SS2.png",
            githubUrl: "https://github.com/Amirularif/OnlineCardShoppingWebsite",
            labels: ["ReactJs", "Javascript", "MUI", "HTML", "CSS", "Figma"]
          },
    ];

    return (
        <div ref={ref} className="work-section">
            <div className="title-container">
                <label className="page-title"> My Projects</label>
                <div className="title-underline"></div>
                <label className="page-desc">A collection of my projects</label>
            </div>
            <div className="work-content-container">
            {projects.map((project, index) => (
                <ProjectCard key={index} project={project} />
            ))}
            </div>
        </div>

    );
});
export default WorkSection;