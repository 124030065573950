import React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import './ProjectCard.css';


function ProjectCard({ project }) {
  return (
    <div className="project-card">
      <div className="project-img-container">
        <img src={require(`../Assets/Images/ProjectImg/${project.imageUrl}`)} alt={project.title} className="project-image" />
      </div>
      <div className="project-info">
        <div className="project-title-container">
          <label className="project-title">{project.title}</label>
          <div className="project-links">
          {project.siteUrl && (
            <a href={project.siteUrl} target="_blank" rel="noopener noreferrer">
              <OpenInNewIcon sx={{ color: 'var(--primary-text-color)'}} />
            </a>
          )}
          {project.githubUrl && (
            <a href={project.githubUrl} target="_blank" rel="noopener noreferrer">
              <GitHubIcon sx={{ color: 'var(--primary-text-color)'}}  />
            </a>
          )}
        </div>
        </div>
        <div className="project-desc-container">
          <label className="project-desc">{project.description}</label>
        </div>
        <div className="project-labels">
          {project.labels.map((label, index) => (
            <span key={index} className="label">{label}</span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
