import React from 'react';
import './HeroSection.css';

function HeroSection({scrollToAboutMe}){
    return (
        <div className="retro-grid-container">
          <div className="retro-title-container">
            <label className="welcome-label"> Hello, I'm a</label>
            <h1 className="retro-title">FRONTEND DEVELOPER</h1>
            <button onClick={scrollToAboutMe} class="button-85">PRESS START</button>
          </div>
          <div className="grid-background"></div>
        </div>
      );
}

export default HeroSection;
