
const SkillData = [
    {
        img: "JS.png",
        title: "Javascript",
        exp: "2+ year" 
    },
    {
        img: "Blazor.png",
        title: "Blazor",
        exp: "1+ year" 
    },
    {
        img: "React.png",
        title: "React",
        exp: "2+ year" 
    },
    {
        img: "HTML.png",
        title: "HTML",
        exp: "3 years" 
    },
    {
        img: "NETCORE.png",
        title: ".NET Core",
        exp: "1+ year" 
    },
    {
        img: "Figma.png",
        title: "Figma",
        exp: "3 years" 
    },
    {
        img: "AS.png",
        title: "Android Studio",
        exp: "2 years" 
    },
    {
        img: "Flutter.png",
        title: "Flutter",
        exp: "2 years" 
    },
    {
        img: "Bootstrap.png",
        title: "Bootstrap",
        exp: "1 year" 
    }
];

export default SkillData;
