import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container"> 
      <div className="footer-credits">
        <label className="credits"> Icons by </label> <a href="https://icons8.com" target="_blank" rel="noopener noreferrer"> Icons8 </a>
      </div>
    </div>
  );
}

export default Footer;