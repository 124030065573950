import React from 'react';
import './SkillCard.css'; 

function SkillCard({ img, title, exp }) {

  return (
    <div className="skill-card">
      <div className="skill-brand-container">
        <div className="skill-img-container">
              <img src={require (`../Assets/Images/SkillIcons/${img}`)} alt="Brand Icons" className="skill-image"/>
          </div>
          <label className="skill-label">{title}</label>
        </div>
        <div className="skill-exp-container"> 
            <label className="skill-exp">{exp}</label>
        </div>
    </div>
  );
}

export default SkillCard;
