import React, { useState, useEffect } from 'react';
import { IconButton, Box, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function ScrollTopButton() {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 2000) setVisible(true);
        else setVisible(false);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => window.removeEventListener('scroll', toggleVisible);
    }, []);

    return (
        <Box 
            sx={{
                position: 'fixed',
                bottom: '40px',
                right: '40px',
                display: visible ? 'flex' : 'none',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--secondary-bg-color)',
                borderRadius: '36px',
                border: '1px solid var(--primary-border-color)',
                padding: '6px',
                cursor: 'pointer',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}
            onClick={scrollToTop}
        >
            <Tooltip title="Scroll to top" enterDelay={300} leaveDelay={200}>
                <IconButton aria-label="Back to top">
                    <ArrowUpwardIcon color="primary" sx={{color: 'var(--primary-text-color)', fontSize: '1.2rem'}}/>
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default ScrollTopButton;