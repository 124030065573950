import React, {forwardRef} from 'react';
import { SkillCard } from '../Elements';
import SkillData from '../Model/SkillData';
import './SkillSection.css';


const SkillSection = forwardRef((props, ref) => {
    return (
        <div ref={ref} className="skill-section">
            <div className="title-container">
                <label className="page-title">Tech Stack</label>
                <div className="title-underline"></div>
                <label className="page-desc">I'm Learning more as I go </label>
            </div>
            <div className="skill-content-container">
            {SkillData.map((skill, index) => (
                <SkillCard key={index} img={skill.img} title={skill.title} exp={skill.exp} />
            ))}
            </div>
        </div>

    );
});

export default SkillSection;

