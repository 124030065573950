import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton'; 
import CircleIcon from '@mui/icons-material/Circle';
import './SowCard.css'; 

function SowCard({ title, description, imageUrl }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`sow-card ${expanded ? 'expanded' : ''}`}>
      <div className="card-content">
        <CircleIcon className="circle-icon" sx={{color: 'var(--primary-text-color)'}} fontSize='Small' />
        <label className={`card-title ${expanded ? 'gradient-title' : ''}`}>
          {title}
        </label>
        <p className="card-description"> {description}
          <div className={`sow-img-container ${expanded ? 'show' : 'hide'}`}>
            <img src={require(`../Assets/Images/${imageUrl}`)} alt="Scope of Work" className="sow-image"/>
          </div>
        </p>
        <IconButton sx={{color: 'var(--secondary-text-color)'}} onClick={handleExpandClick} className="expand-icon" aria-label="show more">
          {expanded ? <RemoveIcon className="expand-icon" /> : <AddIcon className="expand-icon" />}
        </IconButton>
      </div>
    </div>
  );
}

export default SowCard;
