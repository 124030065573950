import React, {forwardRef} from 'react';
import { useInView } from 'react-intersection-observer';
import { ContactCard } from '../Elements';
import './ContactSection.css';


const ContactSection = forwardRef((props, ref) => {

    const { ref: card1Ref, inView: card1InView } = useInView({
        triggerOnce: true,
        threshold: 0
    });
    const { ref: card2Ref, inView: card2InView } = useInView({
        triggerOnce: true,
        threshold: 0
    });
    const { ref: card3Ref, inView: card3InView } = useInView({
        triggerOnce: true,
        threshold: 0
    });

    return (
        <div ref={ref} className="contact-section">
            <div className="title-container">
                <label className="page-title">Contacts</label>
                <div className="title-underline"></div>
                <label className="page-desc">Got a project in mind?</label>
            </div>
            <div className="content-container">
                <ContactCard 
                    ref={card1Ref}
                    className={card1InView ? 'card-slide-up' : ''}
                    title="Drop me an Email!"
                    desc="Gmail"
                    linkUrl={"mailto:amirularif.gg@gmail.com"}/>
                <ContactCard 
                    ref={card2Ref}
                    className={card2InView ? 'card-slide-up' : ''}
                    title="Message me on whatsapp!"
                    desc="Whatsapp"
                    linkUrl={"https://wa.me/60172465374"}/>
                <ContactCard 
                    ref={card3Ref}
                    className={card3InView ? 'card-slide-up' : ''}
                    title="Connect with me on Linkedin"
                    desc="Linkedin"
                    linkUrl={"https://linkedin.com/in/amirul-arif-gg/"}/>
            </div>
        </div>

    );
});

export default ContactSection;