import React, {useRef} from 'react';
import { ThemeProvider } from './ThemeContext';
import { Header, Footer, ScrollToTop } from './Components';
import { HeroSection, AboutMeSection, SkillSection, WorkSection, ContactSection } from './Sections';
import './App.css';

function App() {
  const AboutMeRef = useRef(null);
  const SkillRef = useRef(null);
  const WorkRef = useRef(null);
  const ContactRef = useRef(null);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth'
    });
  };

  return (
    <ThemeProvider>
      <div className="body-content">
      <Header
        scrollToAboutMe={() => scrollToRef(AboutMeRef)}
        scrollToSkill={() => scrollToRef(SkillRef)}
        scrollToWork={() => scrollToRef(WorkRef)}
        scrollToContact={() => scrollToRef(ContactRef)} />
      <HeroSection
        scrollToAboutMe={() => scrollToRef(AboutMeRef)} />
      <div className="marquee-container">
        <div class="marquee">
          <div class="track">
            <div class="content">&nbsp;Last update on 20th November 2024 at 11:32 am. I will try my best to constantly update this page peace. 
            &nbsp; Last update on 20th November 2024 at 11:32 am. I will try my best to constantly update this page peace.
            &nbsp; Last update on 20th November 2024 at 11:32 am. I will try my best to constantly update this page peace.
            &nbsp; Last update on 20th November 2024 at 11:32 am. I will try my best to constantly update this page peace.
            </div>
          </div>
        </div>
      </div>
      <AboutMeSection ref={AboutMeRef}/>
      <SkillSection ref={SkillRef} />
      <WorkSection ref={WorkRef}/>
      <ContactSection ref={ContactRef} />
      <Footer />
      <ScrollToTop />
      </div>
    </ThemeProvider>
  );
}

export default App;
